import React from "react";
import { useStaticQuery, graphql } from "gatsby";

const TwoColumnSection = ({ headingLevel, className }) => {
  const data = useStaticQuery(graphql`
    {
      icon1: file(
        relativePath: { eq: "6.0 Product Page/TwoColumn/Icon 1.svg" }
      ) {
        publicURL
      }
      icon2: file(
        relativePath: { eq: "6.0 Product Page/TwoColumn/Icon 2.svg" }
      ) {
        publicURL
      }
      icon3: file(
        relativePath: { eq: "6.0 Product Page/TwoColumn/Icon 3.svg" }
      ) {
        publicURL
      }
      icon4: file(
        relativePath: { eq: "6.0 Product Page/TwoColumn/Icon 4.svg" }
      ) {
        publicURL
      }
    }
  `);

  const HeadingTag = headingLevel || "h2";

  const content = [
    {
      icon: data.icon1.publicURL,
      heading: "Health of Your Business",
      text: "Get a clear picture of your business’s overall health with insights into your transaction volume and gross commission income. Understand how these numbers impact your bottom line.",
    },
    {
      icon: data.icon2.publicURL,
      heading: "Clarity on Agent Performance",
      text: "Gain visibility into your agents’ daily activities, like phone time, and see how they’re performing. Identify strengths and areas for improvement to help your team excel.",
    },
    {
      icon: data.icon3.publicURL,
      heading: "Lead Source ROI",
      text: "Are your lead sources paying off? Stop wasting money on leads that don’t convert. Get detailed insights into which lead sources are bringing in quality prospects and which ones aren’t delivering results.",
    },
    {
      icon: data.icon4.publicURL,
      heading: "Data Conversion Metrics",
      text: "From lead to closing, you can track every step, from getting a lead to setting up an appointment. See how many contacts it takes to schedule a meeting and how many leads are needed to close a deal. Optimize your process for better results.",
    },
  ];

  return (
    <section className={`${className || "mb-20 md:mb-32"}`}>
      <div className="container mx-auto max-w-[1267px]">
        <header className="mx-auto mb-12 max-w-[680px] md:mb-20 md:text-center">
          <HeadingTag className="text-center">
            Realsynch Unlocks the Power of Your Data
          </HeadingTag>
        </header>

        <div className="relative z-10 grid gap-y-12 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
          {content.map((content, i) => {
            return (
              <div
                key={i}
                className="flex flex-col items-center gap-y-3 lg:items-start"
              >
                <img
                  src={content.icon}
                  alt={content.heading}
                  className="mx-auto h-16 w-16 lg:mx-0"
                />
                <div>
                  <h3 className="heading-four mb-3 text-center lg:!text-left ">
                    {content.heading}
                  </h3>
                  <p className="mb-0 text-center lg:!text-left ">
                    {content.text}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default TwoColumnSection;
