import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import ButtonGhost from "../Button/ButtonGhost";
import ButtonSolid from "../Button/ButtonSolid";

const ProductIntro = ({ headingLevel, className }) => {
  const HeadingTag = headingLevel || "h2";

  return (
    <section className={`${className || "mb-20 md:mb-32 lg:mt-[87px]"}`}>
      <div className="container mx-auto max-w-[1267px]">
        <div className="grid items-center gap-y-8 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
          <div className="order-2 w-full md:order-1">
            <HeadingTag>
              Business Intelligence: Unlock Your Data and Transform Insights
              Into Action
            </HeadingTag>
            <p>
              Stop worrying about failure. Realsynch’s BI platform—designed
              specifically for real estate—has you covered. Our done-for-you
              solution identifies the holes in your business, allowing you to
              easily focus your efforts where they matter most and:
            </p>
            <ul className="mb-10 flex flex-col gap-y-2">
              <li className="flex gap-x-[11px]">
                <StaticImage
                  src="../../images/1.0 Homepage/bullet-check.svg"
                  loading="lazy"
                  width={25}
                  height={25}
                />
                Instantly review business data
              </li>
              <li className="flex gap-x-[11px]">
                {" "}
                <StaticImage
                  src="../../images/1.0 Homepage/bullet-check.svg"
                  loading="lazy"
                  width={25}
                  height={25}
                />
                Make informed decisions
              </li>
              <li className="flex gap-x-[11px]">
                {" "}
                <StaticImage
                  src="../../images/1.0 Homepage/bullet-check.svg"
                  loading="lazy"
                  width={25}
                  height={25}
                />
                Accelerate growth
              </li>
            </ul>

            <ButtonSolid
              altStyle={2}
              // href="/how-it-works/"
              href="/free-trial"
              text="Start Free Trial"
            />
          </div>
          <div className="order-1 flex items-center justify-center md:order-2">
            <StaticImage
              src="../../images/6.0 Product Page/intro-image.png"
              loading="lazy"
              objectFit="contain"
              width={648}
              height={467}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductIntro;
