import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../../components/Layout";
import SearchEngineOptimization from "../../components/SEO";
import CallToAction from "../../components/Repeating/CTA";
import ButtonSolid from "../../components/Button/ButtonSolid";
import TrustedBy from "../../components/Repeating/TrustedBy";
import ProductIntro from "../../components/Repeating/ProductIntro";
import SliderLogos1 from "../../components/Slider/SliderLogos1";
import SliderLogos2 from "../../components/Slider/SliderLogos2";
import TwoColumnSection from "../../components/Repeating/TwoColumnSection";
import ReviewsGrid from "../../components/Repeating/ReviewsGrid";

const icons1 = [
  "BoomTown.png",
  "Brivity-Platform.png",
  "chime.png",
  "conversion monster.png",
  "dotloop.png",
  "evabot.png",
  "Firepoint-Logo.png",
];

const icons2 = [
  "floify.png",
  "Follow-up-boss.png",
  "Homelight.png",
  "keller mortgage.png",
  "kvcore 1.png",
  "loopnet.png",
  "Lion Desk.png",
  "keller mortgage.png",
];

const icons3 = [
  "Ojo Labs.png",
  "opcity.png",
  "realgeeks.png",
  "revaluate-logo-dark 1.png",
  "Salesforce.png",
  "Sierra Interactive.png",
  "Sisu.png",
  "revaluate-logo-dark 1.png",
];

const icons4 = [
  "structurely.png",
  "Velocify.png",
  "voicepad.png",
  "Vyral.png",
  "ylopo-logo.png",
  "Zillow-Logo.png",
];

const Page = ({ data }) => {
  const HeadingTag = "h2";
  return (
    <Layout>
      <SearchEngineOptimization
        title="Business Intelligence Platform | Data Analytics & Visualization | Realsynch"
        description="Transform your data into actionable insights with Realsynch's decision intelligence platform. Unlock the power of predictive analytics and augmented analytics for smarter business decisions."
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <ProductIntro />
      <TrustedBy />
      <section className="my-[120px] overflow-x-hidden bg-primary-900 bg-product-app-slider bg-contain bg-left ">
        <div className="mx-auto flex w-full max-w-[1267px] grid-cols-1 flex-col justify-center gap-y-10 lg:grid lg:grid-cols-2 lg:gap-x-10">
          <header className="mx-auto mt-20 flex w-full max-w-[800px] flex-col items-center justify-center px-4  text-center md:mb-20 lg:mt-0 lg:mb-0 lg:items-start">
            <h2 className="w-full text-center text-white lg:text-left">
              <span className="bg-gradient-to-r from-[#3D3DF1] via-[#9F4ED1] to-[#FEA6DD] bg-clip-text pr-[2px] italic text-transparent">
                Easily Connect
              </span>{" "}
              What
              <br /> You're Already Using
            </h2>
            <p className="max-w-[596px] text-center text-white/80">
              We synchronize every tool you use and provide you with data
              visualization so you can easily see what’s working and what isn’t.
              Realsynch keeps you focused on ROI, sheds light on agent
              performance, and helps get your team to peak productivity.
            </p>
          </header>

          <div className="col-start-2 max-h-[802px] w-full rotate-90 overflow-hidden lg:w-[640px]">
            <SliderLogos1>
              {icons4.map((icon, index) => (
                <div
                  key={index}
                  className="mx-4 flex !h-[144px] !w-[144px] rounded-[24px] bg-brand-gradient-2 p-[4px]"
                >
                  <div className="flex h-full w-full items-center justify-center rounded-[20px] bg-[#1f1f54] ">
                    <img
                      src={`/images/Apps/${icon}`}
                      loading="lazy"
                      width={120}
                      height={27}
                      className="h-[27px] w-[120px] -rotate-90 object-contain"
                    />
                  </div>
                </div>
              ))}
            </SliderLogos1>
            <SliderLogos2>
              {icons3.map((icon, index) => (
                <div
                  key={index}
                  className="mx-4 flex !h-[144px] !w-[144px] rounded-[24px] bg-brand-gradient-2 p-[4px]"
                >
                  <div className="flex h-full w-full items-center justify-center rounded-[20px] bg-[#1f1f54] ">
                    <img
                      src={`/images/Apps/${icon}`}
                      loading="lazy"
                      width={120}
                      height={27}
                      className="h-[27px] w-[120px] -rotate-90 object-contain"
                    />
                  </div>
                </div>
              ))}
            </SliderLogos2>
            <SliderLogos1>
              {icons2.map((icon, index) => (
                <div
                  key={index}
                  className="mx-4 flex !h-[144px] !w-[144px] rounded-[24px] bg-brand-gradient-2 p-[4px]"
                >
                  <div className="flex h-full w-full items-center justify-center rounded-[20px] bg-[#1f1f54] ">
                    <img
                      src={`/images/Apps/${icon}`}
                      loading="lazy"
                      width={120}
                      height={27}
                      className="h-[27px] w-[120px] -rotate-90 object-contain"
                    />
                  </div>
                </div>
              ))}
            </SliderLogos1>
            <SliderLogos2>
              {icons1.map((icon, index) => (
                <div
                  key={index}
                  className="mx-4 flex !h-[144px] !w-[144px] rounded-[24px] bg-brand-gradient-2 p-[4px]"
                >
                  <div className="flex h-full w-full items-center justify-center rounded-[20px] bg-[#1f1f54] ">
                    <img
                      src={`/images/Apps/${icon}`}
                      loading="lazy"
                      width={120}
                      height={27}
                      className="h-[27px] w-[120px] -rotate-90 object-contain"
                    />
                  </div>
                </div>
              ))}
            </SliderLogos2>
          </div>
        </div>
      </section>
      <section className={"mb-20 md:mb-32"}>
        <div className="container mx-auto max-w-[1267px]">
          <div className="grid items-center gap-y-8 md:grid-cols-1 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 flex flex-col items-center md:order-1">
              <HeadingTag className="text-center">
                Get Real-Time Business Analytics
              </HeadingTag>
              <p className="mx-auto max-w-[640px] text-center">
                All of your transaction data, lead source, and activities are
                integrated, aggregated, and analyzed in real-time. You get an
                easy-to-read dashboard that gives you actionable insights to
                help you maximize profitability.
              </p>
            </div>
            <div className="order-1 md:order-2">
              <StaticImage
                src="../../images/6.0 Product Page/dashboard.png"
                loading="lazy"
                objectFit="contain"
                width={2627}
                height={1831}
              />
            </div>
          </div>
        </div>
      </section>
      <section className={"mt-20 mb-20 md:mb-32 lg:mt-[200px]"}>
        <div className="container mx-auto max-w-[1267px]">
          <div className="grid items-center gap-y-8 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-2">
              <HeadingTag>Monitor Agent Performance</HeadingTag>
              <p>
                See who’s performing and who’s not. Realsynch automatically
                pulls in an agent’s dial attempts, call time, contacts,
                appointments set, won/loss conversion rates, contract
                executions, and closings.
              </p>
            </div>
            <div className="order-1 md:order-1">
              <StaticImage
                src="../../images/6.0 Product Page/monitor-performance.png"
                loading="lazy"
                objectFit="contain"
                width={1442}
                height={1180}
              />
            </div>
          </div>
        </div>
      </section>
      <section className={"mt-20 mb-20 md:mb-32 lg:mt-[200px]"}>
        <div className="container mx-auto max-w-[1267px]">
          <div className="grid items-center gap-y-8 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <HeadingTag>
                Clear Insights. Actionable Steps. Accelerated Growth.
              </HeadingTag>
              <p>
                Confused about where your leads are coming from? Tired of
                juggling data between different systems? Struggling to use your
                agents' strengths or fix their weaknesses? Realsynch can help.
                It’s the key difference between the many real estate
                professionals who struggle and the few who truly succeed.
              </p>

              <ButtonSolid
                altStyle={2}
                href="/free-trial"
                text="Start Free Trial"
              />
            </div>
            <div className="order-1 md:order-2">
              <StaticImage
                src="../../images/6.0 Product Page/dashboard2.png"
                loading="lazy"
                objectFit="contain"
                width={1176}
                height={840}
              />
            </div>
          </div>
        </div>
      </section>
      <TwoColumnSection />
      <ReviewsGrid />
      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Global.jpg" }
    ) {
      publicURL
    }
    optimizeYourPipeline: file(
      relativePath: { eq: "3.0 Features/2. Optimize Your Pipeline.svg" }
    ) {
      publicURL
    }
    createEfficiency: file(
      relativePath: { eq: "3.0 Features/4.0 Create Efficiency.svg" }
    ) {
      publicURL
    }
    integrationAutomationReports: file(
      relativePath: {
        eq: "3.0 Features/5.0 Integration _ Automation Reports.svg"
      }
    ) {
      publicURL
    }
    success1: file(
      relativePath: { eq: "3.0 Features/3.0 Free Strategy Call.svg" }
    ) {
      publicURL
    }
    success2: file(
      relativePath: { eq: "3.0 Features/3.1 Dedicated Support Agent.svg" }
    ) {
      publicURL
    }
    success3: file(
      relativePath: { eq: "3.0 Features/3.2 Easy Ways To Connect With Us.svg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
